<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="formType === 'ADD' ? $t('belirumaUser.add') : $t('agent.detail')"
    class="px-5 py-3"
    v-if="detail || formType === 'ADD'"
  >
    <form @submit.prevent="submit">
      <v-row>
        <v-col cols="6" class="border--right">
          <div class="form--group row" v-if="formType !== 'ADD'">
            <label class="col-12 col-lg-4">Uuid Agen</label>
            <div class="col-12 col-lg-8">
              {{ uuid }}
            </div>
          </div>
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('password') }"
            v-if="formType === 'ADD'"
          >
            <label class="col-12 col-lg-4">Password</label>
            <div class="col-12 col-lg-8">
              <v-text-field type="text" outlined hide-details v-model="password" />
              <span class="val-error">{{ validation.firstError('password') }}</span>
            </div>
          </div>
          <div class="form--group row" v-else-if="permissions.includes('EDIT')">
            <label class="col-12 col-lg-4">Ganti Password</label>
            <div class="col-12 col-lg-8">
              <v-btn color="primary" class="bottom--button" @click="changePassword">
                {{ $t('agent.btn.changePassword') }}
              </v-btn>
            </div>
          </div>
          <div class="form--group row" :class="{ 'has-error': validation.hasError('name') }">
            <label class="col-12 col-lg-4">Nama Agen</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                v-model="name"
                :readonly="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('name') }}</span>
            </div>
          </div>
          <div class="form--group row" :class="{ 'has-error': validation.hasError('email') }">
            <label class="col-12 col-lg-4">Email Agen</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="email"
                outlined
                hide-details
                v-model="email"
                :readonly="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('email') }}</span>
            </div>
          </div>
          <div class="form--group row" :class="{ 'has-error': validation.hasError('phone') }">
            <label class="col-12 col-lg-4">Nomor Telepon Agen</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="tel"
                outlined
                hide-details
                v-model="phone"
                :readonly="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('phone') }}</span>
            </div>
          </div>
          <agent-photo-section
            :isEditing="isEditing"
            v-if="formType !== 'ADD'"
          ></agent-photo-section>
          <div class="form--group row">
            <label class="col-12 col-lg-4">{{ $t('agentMember.memberType') }}</label>
            <div class="col-12 col-lg-8">
              {{ membership ? membership.type : '' }}
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">{{ $t('agentMember.memberStart') }}</label>
            <div class="col-12 col-lg-8">
              {{
                membership && membership.start_at
                  ? format(new Date(membership.start_at), 'dd MMMM yyyy HH:mm')
                  : ''
              }}
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="form--group row" :class="{ 'has-error': validation.hasError('nib') }">
            <label class="col-12 col-lg-4">NIB Agen</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                v-model="nib"
                :readonly="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('nib') }}</span>
            </div>
          </div>
          <div class="form--group row" :class="{ 'has-error': validation.hasError('agentType') }">
            <label class="col-12 col-lg-4">Tipe Agen</label>
            <div class="col-12 col-lg-8">
              <multiselect
                name="agentType"
                id="agentTypeField"
                v-model="agentType"
                :options="agentTypes"
                :searchable="true"
                :close-on-select="true"
                track-by="id"
                label="name"
                :disabled="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('agentType') }}</span>
            </div>
          </div>
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('agentOffice') }"
            v-show="agentType && agentType.id === 2"
          >
            <label class="col-12 col-lg-4">Kantor Properti</label>
            <div class="col-12 col-lg-8">
              <multiselect
                name="agentOffice"
                id="agentOfficeField"
                v-model="agentOffice"
                :options="agentOffices"
                :searchable="true"
                :close-on-select="true"
                track-by="id"
                label="name"
                :disabled="!isEditing"
              >
                <template slot="option" slot-scope="{ option }">
                  <div class="agent--ofice-option">
                    <div class="option--img" v-if="option.logo_url">
                      <img :src="option.logo_url" :alt="option.name" />
                    </div>
                    <div class="option--text">
                      {{ option.name }}
                    </div>
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  <div class="agent--ofice-option">
                    <div class="option--img" v-if="option.logo_url">
                      <img :src="option.logo_url" :alt="option.name" />
                    </div>
                    <div class="option--text">
                      {{ option.name }}
                    </div>
                  </div>
                </template>
              </multiselect>
              <span class="val-error">{{ validation.firstError('agentOffice') }}</span>
            </div>
          </div>
          <prop-spec-section :isEditing="isEditing"></prop-spec-section>
          <price-spec-section :isEditing="isEditing"></price-spec-section>
          <area-spec-section :isEditing="isEditing"></area-spec-section>
          <!--          <note-section :isEditing="isEditing"></note-section>-->
          <div class="mt-6">
            {{ $t('general.bankInfo') }}
          </div>
          <hr class="mt-2 mb-4" />
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('selectedBank') }"
          >
            <label class="col-12 col-lg-4">Nama Bank</label>
            <div class="col-12 col-lg-8">
              <multiselect
                name="selectedBank"
                id="selectedBankField"
                v-model="selectedBank"
                :options="banks"
                :searchable="true"
                :close-on-select="true"
                track-by="id"
                label="name"
                :disabled="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('selectedBank') }}</span>
            </div>
          </div>
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('accountNumber') }"
          >
            <label class="col-12 col-lg-4">Nomor Rekening</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                v-model="accountNumber"
                :readonly="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('accountNumber') }}</span>
            </div>
          </div>
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('accountHolder') }"
          >
            <label class="col-12 col-lg-4">Nama Pemilik Rekening</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                v-model="accountHolder"
                :readonly="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('accountHolder') }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </form>
    <div
      class="d-flex align-center justify-center"
      style="margin-top: 32px"
      v-if="permissions.includes('EDIT')"
    >
      <v-btn color="primary" class="bottom--button" @click="startEdit" v-if="!isEditing">
        {{ $t('agent.btn.startEdit') }}
      </v-btn>
      <v-btn color="primary" class="bottom--button" @click="submit" v-else>
        {{ $t('agent.btn.save') }}
      </v-btn>
      <v-btn color="primary" class="bottom--button" @click="suspend" v-if="statusId === 1">
        {{ $t('agent.btn.suspend') }}
      </v-btn>
      <v-btn color="primary" class="bottom--button" @click="activate" v-else-if="statusId === 2">
        {{ $t('agent.btn.activate') }}
      </v-btn>
      <base-delete-button
        :permissions="permissions"
        :id="uuid"
        @deleteAction="deleteAction"
        v-if="uuid"
      ></base-delete-button>
    </div>
    <div
      class="d-flex align-center justify-center"
      style="margin-top: 32px"
      v-if="formType === 'ADD'"
    >
      <v-btn color="primary" class="bottom--button" @click="submit">
        {{ $t('agent.btn.save') }}
      </v-btn>
    </div>
    <v-dialog v-model="showConfirmEdit" max-width="300">
      <v-card>
        <v-card-title>
          Apakah Anda yakin ingin mengubah data agen ini?

          <v-spacer />

          <v-icon aria-label="Close" @click="showConfirmEdit = false"> mdi-close </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn color="error" text @click="showConfirmEdit = false">
            {{ $t('general.no') }}
          </v-btn>

          <v-btn color="primary" text @click="submitAction">
            {{ $t('general.yes') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { format } from 'date-fns';
import AgentPhotoSection from '@/components/user-management/beliruma-user/partials/agent-photo-section.vue';
import PropSpecSection from '@/components/user-management/beliruma-user/partials/prop-spec-section.vue';
import PriceSpecSection from '@/components/user-management/beliruma-user/partials/price-spec-section.vue';
import AreaSpecSection from '@/components/user-management/beliruma-user/partials/area-spec-section.vue';
// import NoteSection from '@/components/user-management/beliruma-user/partials/note-section.vue';
import validation from 'simple-vue-validator/src/validation-bag';
import swal from 'sweetalert2';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  components: {
    AgentPhotoSection,
    Multiselect,
    PropSpecSection,
    PriceSpecSection,
    AreaSpecSection,
    // NoteSection,
  },
  data() {
    return {
      format,
      isEditingData: false,
      showConfirmEdit: false,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
      formType: (state) => state.belirumaUser.form.formType,
      uuid: (state) => state.belirumaUser.form.uuid,
      oldEmail: (state) => state.belirumaUser.form.oldEmail,
      banks: (state) => state.belirumaUser.form.banks,
      membership: (state) => state.belirumaUser.form.membership,
      agentTypes: (state) => state.belirumaUser.form.agentTypes,
      agentOffices: (state) => state.belirumaUser.form.agentOffices,
      statusId: (state) => state.belirumaUser.form.statusId,
    }),
    isEditing() {
      return this.isEditingData || this.formType === 'ADD';
    },
    name: {
      get() {
        return this.$store.state.belirumaUser.form.name;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_NAME', value);
      },
    },
    password: {
      get() {
        return this.$store.state.belirumaUser.form.password;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_PASSWORD', value);
      },
    },
    email: {
      get() {
        return this.$store.state.belirumaUser.form.email;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_EMAIL', value);
      },
    },
    phone: {
      get() {
        return this.$store.state.belirumaUser.form.phone;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_PHONE', value);
      },
    },
    agentType: {
      get() {
        return this.$store.state.belirumaUser.form.agentType;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_AGENT_TYPE', value);
      },
    },
    agentOffice: {
      get() {
        return this.$store.state.belirumaUser.form.office;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_OFFICE', value);
      },
    },
    selectedBank: {
      get() {
        return this.$store.state.belirumaUser.form.selectedBank;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_SELECTED_BANK', value);
      },
    },
    accountHolder: {
      get() {
        return this.$store.state.belirumaUser.form.accountHolder;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_ACCOUNT_HOLDER', value);
      },
    },
    accountNumber: {
      get() {
        return this.$store.state.belirumaUser.form.accountNumber;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_ACCOUNT_NUMBER', value);
      },
    },
    nib: {
      get() {
        return this.$store.state.belirumaUser.form.nib;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_NIB', value);
      },
    },
  },
  watch: {
    agentType(val) {
      if (val == null || val.id !== 2) {
        this.agentOffice = null;
      }
      this.$validate();
    },
  },
  validators: {
    name(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.agent.name.required'))
        .maxLength(150, this.$i18n.t('errors.agent.name.max', { maxChar: 150 }));
    },
    password(value) {
      if (this.formType === 'ADD') {
        return Validator.value(value)
          .required(this.$i18n.t('errors.agent.password.required'))
          .minLength(8, this.$i18n.t('errors.agent.password.min', { minChar: 8 }));
      } else {
        return Validator.value(value);
      }
    },
    email: {
      debounce: 250,
      validator: function (value) {
        if ((this.isEditing && this.email !== this.oldEmail) || this.formType === 'ADD') {
          return Validator.value(value)
            .required(this.$i18n.t('errors.agent.email.required'))
            .email(this.$i18n.t('errors.agent.email.invalid'))
            .maxLength(255, this.$i18n.t('errors.agent.email.max', { maxChar: 255 }))
            .custom(async () => {
              const { data } = await this.$store.dispatch('belirumaUser/form/checkEmail', value);
              if (data.exist) {
                return this.$i18n.t('errors.agent.email.exist');
              }
            });
        } else {
          return Validator.value(value);
        }
      },
    },
    phone(value) {
      if (this.formType === 'ADD') {
        return Validator.value(value).required(this.$i18n.t('errors.agent.phone.required'));
      } else {
        return Validator.value(value);
      }
    },
    agentType(value) {
      return Validator.value(value).required(this.$i18n.t('errors.agent.agentType.required'));
    },
    agentOffice(value) {
      if (this.agentType && this.agentType.id === 2) {
        return Validator.value(value).required(this.$i18n.t('errors.agent.agentOffice.required'));
      } else {
        return Validator.value(value);
      }
    },
  },
  mounted() {
    if (this.formType === 'ADD') {
      this.startEdit();
    } else {
      this.isEditingData = false;
    }
  },
  methods: {
    async deleteAction(uuid) {
      try {
        let data = await this.$store.dispatch('belirumaUser/form/checkDeleteAccount', uuid);
        if (data.error !== null) {
          await this.$swal(this.$i18n.t('general.error'), data.error, 'error');
        } else {
          await this.$store.dispatch('belirumaUser/form/delete', uuid);
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('agent.delete.successMsg'),
            'success',
          );
          this.$router.back();
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    startEdit() {
      this.isEditingData = true;
      // this.$store.dispatch('belirumaUser/form/postData');
    },
    async submit() {
      if (await this.$validate()) {
        if (this.formType === 'ADD') {
          await this.submitAction();
        } else {
          this.showConfirmEdit = true;
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    async submitAction() {
      this.showConfirmEdit = false;
      await this.$store.dispatch('belirumaUser/form/postData');
      await this.$swal(
        this.$i18n.t('general.success'),
        this.formType === 'ADD'
          ? this.$i18n.t('agent.add.successMsg')
          : this.$i18n.t('agent.update.successMsg'),
        'success',
      );
      this.isEditingData = false;
      if (this.formType !== 'ADD') {
        await this.$store.dispatch('belirumaUser/getDetailInitData', this.$route.params.uuid);
      } else {
        this.$router.back();
      }
    },
    async suspend() {
      try {
        let result = await this.$swal({
          type: 'warning',
          title: this.$i18n.t('agent.suspend.confirmT'),
          text: this.$i18n.t('agent.suspend.confirmD'),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          cancelButtonText: this.$i18n.t('general.no'),
        });
        if (result.value) {
          await this.$store.dispatch('belirumaUser/form/suspend');
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('agent.suspend.successMsg'),
            'success',
          );
          this.$router.go();
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.log('ERROR SUSPENDING USER: ', e);
      }
    },
    async activate() {
      try {
        let result = await this.$swal({
          type: 'warning',
          title: this.$i18n.t('agent.activate.confirmT'),
          text: this.$i18n.t('agent.activate.confirmD'),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          cancelButtonText: this.$i18n.t('general.no'),
        });
        if (result.value) {
          await this.$store.dispatch('belirumaUser/form/activate');
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('agent.activate.successMsg'),
            'success',
          );
          this.$router.go();
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.log('ERROR ACTIVATING USER: ', e);
      }
    },
    async changePassword() {
      try {
        let result = await this.$swal({
          type: 'warning',
          title: this.$i18n.t('agent.changePassword.confirmT'),
          text: this.$i18n.t('agent.changePassword.confirmD'),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          cancelButtonText: this.$i18n.t('general.no'),
        });
        if (result.value) {
          let result2 = await this.$swal({
            type: 'warning',
            title: this.$i18n.t('agent.changePassword.confirm2T'),
            text: this.$i18n.t('agent.changePassword.confirm2D'),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('general.yes'),
            cancelButtonText: this.$i18n.t('general.no'),
          });
          if (result2.value) {
            let password = await this.$store.dispatch('belirumaUser/form/changePassword');
            await this.$swal(
              this.$i18n.t('general.success'),
              `${this.$i18n.t('agent.changePassword.successMsg1')}: "${password}" ${this.$i18n.t(
                'agent.changePassword.successMsg2',
              )}`,
              'success',
            );
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.log('ERROR CHANGE PASSWORD USER: ', e);
      }
    },
  },
};
</script>
