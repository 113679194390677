<template>
  <div class="form--group row">
    <label class="col-12 col-lg-4">Spesialisasi Properti</label>
    <div class="col-12 col-lg-8">
      <multiselect
        name="propertySpec"
        id="propertySpec"
        v-model="selectedPropertySpec"
        :options="propertySpecList"
        :searchable="true"
        :close-on-select="true"
        track-by="id"
        label="name"
        :multiple="true"
        select-label=""
        deselect-label=""
        :disabled="!isEditing"
      ></multiselect>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'prop-spec-section',
  props: ['isEditing'],
  components: { Multiselect },
  computed: {
    ...mapState({
      propertySpecList: (state) => state.belirumaUser.form.propertySpecList,
    }),
    selectedPropertySpec: {
      get() {
        return this.$store.state.belirumaUser.form.propSpecs;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_PROP_SPECS', value);
      },
    },
  },
  methods: {},
};
</script>

<style scoped></style>
