<template>
  <div class="form--group row">
    <label class="col-12 col-lg-4">Foto Profil Agen</label>
    <div class="col-12 col-lg-8">
      <div class="photo--profile-agent" v-if="photoProfile">
        <a :href="photoProfile" target="_blank">
          <img :src="photoProfile" alt="Foto Profil Agen" />
        </a>
      </div>
      <div v-if="isEditing">
        <input
          type="file"
          @change="onFileChange"
          ref="avatar"
          accept="image/*"
          style="display: none"
        />
        <div @click="onPickFile" class="change--photo">
          {{ $t('agentMember.changePhoto') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  props: ['isEditing'],
  components: {},
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
      uuid: (state) => state.belirumaUser.form.uuid,
      photoProfile: (state) => state.belirumaUser.form.photoProfile,
    }),
  },
  methods: {
    onPickFile() {
      this.$refs.avatar.click();
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      let formData = new FormData();
      formData.append('photo_profile', files[0]);
      formData.append('user_uuid', this.uuid);
      this.upload(formData);
    },
    async upload(formData) {
      try {
        await this.$store.dispatch('belirumaUser/form/changeAvatar', formData);
        await this.$swal(
          this.$t('general.success'),
          this.$t('agentMember.updatePhotoSuccess'),
          'success',
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
