<template>
  <div class="form--group row">
    <label class="col-12 col-lg-4">Kisaran Harga Properti</label>
    <div class="col-12 col-lg-8">
      <multiselect
        name="priceSpec"
        id="priceSpec"
        v-model="selectedPriceSpec"
        :options="priceSpecList"
        :searchable="true"
        :close-on-select="true"
        track-by="id"
        label="name"
        :multiple="true"
        select-label=""
        deselect-label=""
        :disabled="!isEditing"
      ></multiselect>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'price-spec-section',
  props: ['isEditing'],
  components: { Multiselect },
  computed: {
    ...mapState({
      priceSpecList: (state) => state.belirumaUser.form.priceSpecList,
    }),
    selectedPriceSpec: {
      get() {
        return this.$store.state.belirumaUser.form.priceSpecs;
      },
      set(value) {
        this.$store.commit('belirumaUser/form/SET_PRICE_SPECS', value);
      },
    },
  },
  methods: {},
};
</script>

<style scoped></style>
